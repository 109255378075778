<template>
  <div class="modal-md rounded-lg bg-white p-6 md:p-6">
    <div class="mb-2 text-center text-xl font-semibold">Swap sample...</div>
    <div>You currently have 3 samples. Remove one to add a new one.</div>
    <div class="mt-4 text-center">Replace...</div>
    <div class="my-4">
      <div
        class="divide-y-2 rounded border-2 bg-white"
        :class="`active-${activeIndex}`"
      >
        <div
          v-for="(item, index) in filteredSamples"
          :key="item.sku"
          class="quick-transition relative py-1 pl-1"
          :class="[
            {
              'z-20 bg-blue-50': activeSample === item.sku,
              'bg-white': activeSample !== item.sku,
              '-mt-6': activeIndex === 1 && index !== 0
            },
            `item-${index}`
          ]"
        >
          <div class="flex w-full items-center space-x-2">
            <div
              class="h-12 w-12 flex-none"
              :class="{
                'opacity-30': activeSample && activeSample !== item.sku
              }"
            >
              <image-getter
                classes="
                object-cover rounded-full w-full h-full
              "
                :url="getImageUrl(item.image.food, 'ar-1_1,w-100')"
                default="/images/product/awaitingimage.webp"
                :key="item.sku"
              />
            </div>
            <div
              class="flex flex-grow flex-col"
              :class="{
                'opacity-30': activeSample && activeSample !== item.sku
              }"
            >
              <div class="flex-grow truncate text-sm">
                {{ item.name }} <span v-if="item.isKitten">(Kitten)</span>
              </div>
              <div class="line-clamp-2 text-xs text-gray-600">
                {{ item.foodcomplete ? 'Complete Food' : 'Complementary Food' }}
                &middot;
                {{ item.foodtype === 'dry' ? item.increments : item.size }}g
              </div>
            </div>
            <div class="w-10">
              <div class="w-10">
                <input
                  type="checkbox"
                  :value="item.sku"
                  :disabled="activeSample && activeSample !== item.sku"
                  @change="setActive(item, index)"
                  :checked="activeSample === item.sku"
                />
                <label>{{ item.label }}</label>
              </div>
            </div>
          </div>
          <div
            v-if="activeSample === item.sku"
            class="pointer-events-none absolute inset-0 z-20 ring ring-[2px] ring-blue-400 ring-offset-0"
          ></div>
        </div>
      </div>
    </div>
    <div>
      <div class="text-center">with..</div>
      <div
        :key="data.sku"
        class="my-4 flex items-center space-x-2 rounded border-2 py-1 pl-1 text-left"
      >
        <div class="h-12 w-12 flex-none">
          <image-getter
            classes="
                object-cover rounded-full w-full h-full
              "
            :url="getImageUrl(data.image.food, 'ar-1_1,w-100')"
            default="/images/product/awaitingimage.webp"
            :key="data.sku"
          />
        </div>
        <div class="flex flex-col text-left">
          <div class="flex-grow truncate text-sm">
            {{ data.name }} <span v-if="data.isKitten">(Kitten)</span>
          </div>
          <div class="line-clamp-2 text-xs text-gray-600">
            {{ data.foodcomplete ? 'Complete Food' : 'Complementary Food' }}
            &middot;
            {{ data.foodtype === 'dry' ? data.increments : data.size }}g
          </div>
        </div>
      </div>
    </div>

    <div>
      <form @submit.prevent="confirm">
        <loading-button
          :is-loading="isLoading"
          :is-enabled="activeSample !== null"
          label="Confirm"
          secondary
          is-full
        ></loading-button>
      </form>
      <button
        class="mt-3 h-10 w-full rounded bg-gray-50 p-2 px-2 text-center font-semibold hover:cursor-pointer hover:border-gray-300 hover:bg-gray-200 focus:outline-none"
        @click="toggle()"
      >
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex/dist/vuex.common.js';
import ImageGetter from '../../ImageGetter.vue';
import { EventBus } from '@/event-bus';
import LoadingButton from '../../LoadingButton.vue';
import utils from '@/utils';
export default {
  components: { ImageGetter, LoadingButton },
  name: 'AddSampleModal',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      samples: [],
      activeSample: null,
      activeIndex: null,
      isLoading: false
    };
  },

  computed: {
    ...mapGetters({
      products: 'getProducts',
      activeSamples: 'currentSamplesGetter'
    }),
    filteredSamples() {
      // if at least one sample is checked return the checked samples

      return this.samples;
    }
  },
  methods: {
    ...mapActions({
      addSample: 'addSample'
    }),
    setActive(item, index) {
      this.activeSample = this.activeSample === item.sku ? null : item.sku;
      this.activeIndex = this.activeSample !== item.sku ? null : index;
    },
    toggle() {
      this.$store.commit('hide_modal');
    },
    confirm() {
      this.isLoading = true;
      const data = [...this.activeSamples];
      // find the activeSample in data and remove it and push  this.data.sku to the end
      const index = data.findIndex((item) => item === this.activeSample);
      data.splice(index, 1);
      data.push(this.data.sku);
      this.addSample(data).then((response) => {
        this.$store.commit('update_samples', response);
        this.$store.commit('hide_modal');
        this.isLoading = false;
        EventBus.$emit('added-sample-success', this.data.sku);
      });
    },
    getImageUrl(url, config) {
      return utils.transformImage(url, config);
    }
  },

  mounted() {
    // for each item in data find the product and push to the samples array
    this.activeSamples.forEach((item) => {
      const product = this.products.find((p) => p.sku === item);
      product.checked = false;
      this.samples.push(product);
    });
  }
};
</script>

<style lang="scss" scoped>
.active-0 {
  .item-1 {
    margin-top: -2.5rem;
    z-index: 1;
    border-bottom: 2px solid #e2e8f0;
  }
  .item-2 {
    margin-top: -2.5rem;
    z-index: 0;
  }
}
.active-1 {
  .item-1 {
    margin-top: -2.5rem;
    z-index: 1;
  }
  .item-2 {
    margin-top: -2.5rem;
    z-index: 0;
  }
}
.active-2 {
  .item-1 {
    margin-top: -2.5rem;
    z-index: 1;
  }
  .item-2 {
    margin-top: -2.5rem;
    z-index: 2;
  }
}
</style>
